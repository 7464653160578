$(document).on("turbo:load", function () {
  const $allCheckbox = $('#cypher_genre_allowed_cypher_types_all');
  const $otherCheckboxes = $("input[type='checkbox'][name='cypher_genre[allowed_cypher_types][]']").not($allCheckbox);

  $allCheckbox.on('change', function () {
    if (this.checked) {
      $otherCheckboxes.prop('checked', false);
    }
  });

  $otherCheckboxes.on('change', function () {
    if (this.checked) {
      // Uncheck "All available Cyphers" if any other checkbox is checked
      $allCheckbox.prop('checked', false);
    }
  });
});

$(document).on("turbo:load", function () {
  const $allCheckbox = $('#cypher_genre_allowed_descriptors_all');
  const $otherCheckboxes = $("input[type='checkbox'][name='cypher_genre[allowed_descriptors][]']").not($allCheckbox);

  $allCheckbox.on('change', function () {
    if (this.checked) {
      $otherCheckboxes.prop('checked', false);
    }
  });

  $otherCheckboxes.on('change', function () {
    if (this.checked) {
      $allCheckbox.prop('checked', false);
    }
  });
});

$(document).on("turbo:load", function () {
  const $allCheckbox = $('#cypher_genre_allowed_foci_all');
  const $otherCheckboxes = $("input[type='checkbox'][name='cypher_genre[allowed_foci][]']").not($allCheckbox);

  $allCheckbox.on('change', function () {
    if (this.checked) {
      $otherCheckboxes.prop('checked', false);
    }
  });

  $otherCheckboxes.on('change', function () {
    if (this.checked) {
      // Uncheck "All available Cyphers" if any other checkbox is checked
      $allCheckbox.prop('checked', false);
    }
  });
});

$(document).on("turbo:load", function () {
  const $allCheckbox = $('#cypher_genre_allowed_flavors_all');
  const $otherCheckboxes = $("input[type='checkbox'][name='cypher_genre[allowed_flavors][]']").not($allCheckbox);

  $allCheckbox.on('change', function () {
    if (this.checked) {
      $otherCheckboxes.prop('checked', false);
    }
  });

  $otherCheckboxes.on('change', function () {
    if (this.checked) {
      $allCheckbox.prop('checked', false);
    }
  });
});

$(document).on("turbo:load", function () {
  const $allCheckbox = $('#cypher_genre_allowed_flavors_none');
  const $otherCheckboxes = $("input[type='checkbox'][name='cypher_genre[allowed_flavors][]']").not($allCheckbox);

  $allCheckbox.on('change', function () {
    if (this.checked) {
      $otherCheckboxes.prop('checked', false);
    }
  });

  $otherCheckboxes.on('change', function () {
    if (this.checked) {
      $allCheckbox.prop('checked', false);
    }
  });
});
