$(document).on("turbo:load", function () {
  $(document).on(
    "click",
    "#facet_search_panel ul.selected li, #facet_search_panel ul.selectable li",
    function () {
      const url = $(this).data("href");
      window.location.href = url;
    }
  );
});
