// when clicking the category, filter the list to the selected category (or all)
$(document).on("turbo:load", function () {
  $("[id^='character-builder-category-']").on("click", function () {
    var builderTarget = $(this).data("toggle-character-builder-target");
    // console.log("character-builder-category-");

    // Hide all elements in the second list that do not match the target
    $(".update-character-builder").each(function () {
      if (
        builderTarget === "all" ||
        $(this).data("character-builder-category") === builderTarget
      ) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });

    $("#character-builder-list-scroll-container").scrollTop(0);
    $("#character-builder-description-frame").text(
      "Choose one for more details."
    );
  });
});

// when clicking a from the list, update the form field with the selected name
$(document).on("turbo:load", function () {
  const updateLinks = document.querySelectorAll(".update-character-builder");

  updateLinks.forEach((link) => {
    link.addEventListener("click", function (event) {
      const isSelected = $(this).hasClass("border-mcg-red");
      $(".update-character-builder").removeClass("border-mcg-red");
      if (!isSelected) {
        $(".update-character-builder").addClass("border-secondary-100");
        $(this).removeClass("border-secondary-100");
        $(this).addClass("border-mcg-red");
      } else {
        $(this).addClass("border-mcg-red");
      }

      // console.log("data-character-builder-value");

      const value = this.getAttribute("data-character-builder-value");
      const objectType = this.getAttribute(
        "data-character-builder-object-type"
      );
      const input = $("#cypher_character_" + objectType);

      if (input) {
        input.val(value); // Set the hidden field value
      }
    });
  });
});

// handle typing in the search field and update list with found results
$(document).on("turbo:load", function () {
  function debounce(func, wait) {
    let timeout;
    return function executedFunction() {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    };
  }

  var inputHandler = function () {
    var query = $("#search-input").val();
    const searchUrl = $("#search-input").data("search-url");
    const objectType = $("#search-input").data("object-type");

    $.ajax({
      url: searchUrl,
      data: { search_text: query, object_type: objectType },
      dataType: "json",
      success: function (data) {
        processSearchResults(data);
      },
    });
  };

  $("#search-input").on("input", debounce(inputHandler, 300));

  function processSearchResults(builderTargets) {
    // console.log(builderTargets); // Implement your logic to display the search results.
    $(".update-character-builder").each(function () {
      if (
        builderTargets.length === 0 ||
        builderTargets.includes($(this).attr("id"))
      ) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });

    $("#character-builder-list-scroll-container").scrollTop(0);
    $("#character-builder-description-frame").text(
      "Choose one for more details."
    );
  }
});

// when clicking the category, filter the list to the selected category (or all)
$(document).on("turbo:load", function () {
  $("[id^='character-builder-toggle-']").on("click", function () {
    // console.log("character-builder-toggle-target");

    var builderTarget = $(this).data("character-builder-toggle-target");

    // Hide all elements in the second list that do not match the target
    $(".character-builder-toggle-target").each(function () {
      if (
        builderTarget === "all" ||
        $(this).data("character-builder-target") === builderTarget
      ) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });

    // $("#character-builder-list-scroll-container").scrollTop(0);
    $("#character-builder-description-scroll-container").scrollTop(0);
    $("#character-builder-description-frame").text(
      "Choose one for more details."
    );
  });
});

$(document).on("turbo:load", function () {
  $(".character-builder-add-ability").on("click", function () {
    // console.log("add ability");
    var ability_tag = $(this).data("character-builder-ability");
    var ability_name = $(this).data("character-builder-ability-name");

    $("#character-builder-description-" + ability_tag + "-selected").show();

    var currentValue = $('input[name="selected_abilities"]').val();
    var abilitiesArray = currentValue ? currentValue.split(";") : [];

    if (!abilitiesArray.includes(ability_name)) {
      abilitiesArray.push(ability_name);
    }

    var newValue = abilitiesArray.join(";");
    $('input[name="selected_abilities"]').val(newValue);
  });

  $(".character-builder-remove-ability").on("click", function () {
    // console.log("remove ability");
    var ability_tag = $(this).data("character-builder-ability");
    var ability_name = $(this).data("character-builder-ability-name");

    $("#character-builder-description-" + ability_tag + "-selected").hide();

    var currentValue = $('input[name="selected_abilities"]').val();
    var newValue = currentValue
      .split(";")
      .filter(function (ability) {
        return ability !== ability_name;
      })
      .join(";");
    $('input[name="selected_abilities"]').val(newValue);
  });
});

$(document).on("turbo:load", function () {
  function titleize(sentence) {
    if (!sentence) {
      return "";
    }
    return sentence
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word[0].toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  function refreshSkillsDisplay() {
    // console.log("refresh");
    $("#selected-skills").empty(); // Clear current display
    var skills = $("#selected_skills").val();
    if (!skills) {
      return;
    }

    skills = skills.split(";");
    skills.forEach(function (skill) {
      if (!skill) {
        return;
      }
      var [name, level, source] = skill.split("::");

      //  from ${titleize(source)}
      const skillDisplay = `<div class="flex justify-between items-center skill-display">
        <div>
          <span class=''>${titleize(level)} in ${titleize(name)}</span>
        </div>
        <div class="text-right p-5 character-builder-remove-skill" data-skill="${skill}">
         <span class="whitespace-nowrap	text-mcg cursor-pointer bg-mcg-red text-white rounded-lg border-2 hover:black p-1.5 uppercase">Remove</span>
        </div>`;
      $("#selected-skills").append(skillDisplay);
    });
  }

  refreshSkillsDisplay(); // make sure to load the ui on page load

  $(".character-builder-add-skill").on("click", function () {
    // console.log("add skill");
    var skillName = $("#skill_name").val();

    if (skillName === "") {
      return;
    }
    skillName = skillName.toLowerCase();
    var skillLevel = $("#skill_level").val();
    var skillSource = $("#skill_source").val();
    var skillString =
      skillName.replace(/::|;/g, "") +
      "::" +
      skillLevel.replace(/::|;/g, "") +
      "::" +
      skillSource.replace(/::|;/g, "") +
      ";";
    var currentSkills = $("#selected_skills").val();
    if (!currentSkills.includes(skillName)) {
      $("#selected_skills").val(currentSkills + skillString);
    }
    refreshSkillsDisplay();

    $("#skill_name").val("");
    $("#skill_level").val("Trained");
    // $("#skill_source").val("");
    $("#skill_name").trigger("focus");
  });

  // This handler is added differently because the remove buttons get created from the refreshSkillsDisplay()
  $(document).on("click", ".character-builder-remove-skill", function () {
    // console.log("remove skill");
    var skillToRemove = $(this).data("skill");
    var currentSkills = $("#selected_skills").val();
    var newSkills = currentSkills.replace(skillToRemove + ";", ""); // Remove the skill
    $("#selected_skills").val(newSkills);
    refreshSkillsDisplay();
  });
});

$(document).on("turbo:load", function () {
  $(".tag-button").on("click", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("bg-secondary-600 text-white dark:bg-secondary-600 dark:text-white active");
    } else {
      $(this).addClass("bg-secondary-600 text-white dark:bg-secondary-600 dark:text-white active");
    }

    // Collect active tags by type
    var activeGenreTags = $('div[data-tag-type="genre"].active')
      .map(function () {
        return $(this).data("tag");
      })
      .get();

    var activeCharacterTypeTags = $(
      'div[data-tag-type="character_type"].active'
    )
      .map(function () {
        return $(this).data("tag");
      })
      .get();

    // Filter divs based on selected tags
    $("div[data-genre-tags][data-character-type-tags]").each(function () {
      // Get the div's tags
      var divGenreTags = $(this).data("genre-tags").split(" ");
      var divCharacterTypeTags = $(this).data("character-type-tags").split(" ");

      // Check if div matches at least one tag in each active tag group
      var matchesGenre =
        activeGenreTags.length === 0 ||
        activeGenreTags.some((tag) => divGenreTags.includes(tag));
      var matchesCharacterType =
        activeCharacterTypeTags.length === 0 ||
        activeCharacterTypeTags.some((tag) =>
          divCharacterTypeTags.includes(tag)
        );

      // Show or hide the div based on the checks
      $(this).toggle(matchesGenre && matchesCharacterType);
    });
  });
});

$(document).on("turbo:load", function () {
  $("#focus-save-button").on("click", function (event) {
    const input = $("#cypher_character_focus");
    if (input.val() == "") {
      event.preventDefault();
      error_msg = "Please select a Focus";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
  });

  $("#descriptor-save-button").on("click", function (event) {
    const input = $("#cypher_character_descriptor");
    if (input.val() == "") {
      event.preventDefault();
      error_msg = "Please select a Descriptor";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
  });

  $("#abilities-save-button").on("click", function (event) {
    var currentValue = $('input[name="selected_abilities"]').val();
    var abilitiesArray = currentValue ? currentValue.split(";") : [];
    var cantripsCount = abilitiesArray.filter(function (ability) {
      return ability.toLowerCase().endsWith(' cantrip::modern magic flavor'); // a specific cantrip named "XXXX cantrip"
    }).length;
    var baseAbilityCount = abilitiesArray.length - cantripsCount;
    var cantripsBonus = abilitiesArray.some(function (ability) {
      return ability.toLowerCase() === 'cantrips::modern magic flavor'; // the cantrip skill itself
    }) ? 4 : 0;

    if (baseAbilityCount != max_ability_count) {
      event.preventDefault();
      error_msg = "Please select a total of " + max_ability_count + " abilities, you have " + baseAbilityCount + " selected";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }

    if (cantripsCount != cantripsBonus) {
      event.preventDefault();
      error_msg = "Please select a total of " + cantripsBonus + " cantrips, you have " + cantripsCount + " selected";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
  });

  $("#skills-save-button").on("click", function (event) {
    var currentValue = $('input[name="selected_skills"]').val();
    var skillsArray = currentValue ? currentValue.split(";") : [""];
    // there's a trailing ; that adds one to the length after the split
    if (skillsArray.length - 1 != max_skill_count) {
      event.preventDefault();
      error_msg = "Please select a total of " + max_skill_count + " skills";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
  });

  $("#pool-save-button").on("click", function (event) {
    available = parseInt($("#edge_to_spend").text());
    if (!isNaN(available) && available > 0) {
      event.preventDefault();
      error_msg = "Please assign all of your edge points";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
    available = parseInt($("#pool_to_spend").text());
    if (!isNaN(available) && available > 0) {
      event.preventDefault();
      error_msg = "Please assign all of your pool points";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
    int_edge = parseInt($("#character_intellect_edge").text());
    if (
      !isNaN(int_edge) &&
      !isNaN(int_edge_maximum) &&
      int_edge > int_edge_maximum
    ) {
      event.preventDefault();
      error_msg = "Too many points in Intellect Edge";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
  });

  $("#powershifts-save-button").on("click", function (event) {
    available = parseInt($("#powershift-to-spend").text());
    if (
      !isNaN(available) &&
      starting_total_powershifts != available &&
      available > 0
    ) {
      event.preventDefault();
      error_msg = "Please assign all of your power shifts";
      $("#error_message_block").text(error_msg).removeClass('hidden');
      return;
    }
  });
});

$(document).on("turbo:load", function () {
  function modify_shift(div_id, int_value) {
    available = parseInt($("#powershift-to-spend").text());

    if (isNaN(available)) {
      return;
    }

    if (available < 1 && int_value == 1) {
      return;
    }

    if (available >= starting_total_powershifts && int_value == -1) {
      return;
    }

    current = $(div_id).text();

    val = parseInt(current);
    if (val < 1 && int_value == -1) {
      return;
    }

    val += int_value;

    // maximum number of shifts allowed on one option @ tier 1
    if (val > 3) {
      return;
    }

    $(div_id).text(val);
    $(`${div_id}-value`).val(val);
    $("#powershift-to-spend").text(available - int_value);
  }

  $("#accuracy-shift-up").on("click", function (e) { modify_shift("#accuracy-shift", 1); });
  $("#accuracy-shift-down").on("click", function (e) { modify_shift("#accuracy-shift", -1); });

  $("#dexterity-shift-up").on("click", function (e) { modify_shift("#dexterity-shift", 1); });
  $("#dexterity-shift-down").on("click", function (e) { modify_shift("#dexterity-shift", -1); });

  $("#healing-shift-up").on("click", function (e) { modify_shift("#healing-shift", 1); });
  $("#healing-shift-down").on("click", function (e) { modify_shift("#healing-shift", -1); });

  $("#intelligence-shift-up").on("click", function (e) { modify_shift("#intelligence-shift", 1); });
  $("#intelligence-shift-down").on("click", function (e) { modify_shift("#intelligence-shift", -1); });

  $("#power-shift-up").on("click", function (e) { modify_shift("#power-shift", 1); });
  $("#power-shift-down").on("click", function (e) { modify_shift("#power-shift", -1); });

  $("#resilience-shift-up").on("click", function (e) { modify_shift("#resilience-shift", 1); });
  $("#resilience-shift-down").on("click", function (e) { modify_shift("#resilience-shift", -1); });

  $("#single-attack-shift-up").on("click", function (e) { modify_shift("#single-attack-shift", 1); });
  $("#single-attack-shift-down").on("click", function (e) { modify_shift("#single-attack-shift", -1); });

  $("#strength-shift-up").on("click", function (e) { modify_shift("#strength-shift", 1); });
  $("#strength-shift-down").on("click", function (e) { modify_shift("#strength-shift", -1); });

  $("#flight-shift-up").on("click", function (e) { modify_shift("#flight-shift", 1); });
  $("#flight-shift-down").on("click", function (e) { modify_shift("#flight-shift", -1); });

  $("#increased-range-shift-up").on("click", function (e) { modify_shift("#increased-range-shift", 1); });
  $("#increased-range-shift-down").on("click", function (e) { modify_shift("#increased-range-shift", -1); });

  $("#prodigy-shift-up").on("click", function (e) { modify_shift("#prodigy-shift", 1); });
  $("#prodigy-shift-down").on("click", function (e) { modify_shift("#prodigy-shift", -1); });

  $("#savant-shift-up").on("click", function (e) { modify_shift("#savant-shift", 1); });
  $("#savant-shift-down").on("click", function (e) { modify_shift("#savant-shift", -1); });
});

$(document).on("turbo:load", function () {
  $("#show_species_only_checkbox").on("change", function () {
    let currentUrl = new URL(window.location.href);
    if (this.checked) {
      currentUrl.searchParams.set('show_species_only', '1');
    } else {
      currentUrl.searchParams.delete('show_species_only');
    }
    window.location.href = currentUrl.href;
  });
});
