$(document).on("turbo:load", function () {
  $("#cypher_character_custom_descriptor_div").hide()
  $("#cypher_character_custom_second_descriptor_div").hide()
  $("#cypher_character_custom_focus_div").hide()
  $("#cypher_character_custom_flavor_div").hide()

  $("#cypher_character_descriptor").on("select2:select", function (e) {
    display_help(
      "/help/descriptor",
      $("#cypher_character_descriptor").val()
    );
    $("#cypher_character_custom_descriptor_div").toggle($("#cypher_character_descriptor").val() === "homebrew");
  });

  $("#cypher_character_second_descriptor").on("select2:select", function (e) {
    display_help(
      "/help/descriptor",
      $("#cypher_character_second_descriptor").val()
    );
    $("#cypher_character_custom_second_descriptor_div").toggle($("#cypher_character_second_descriptor").val() === "homebrew")
  });

  $("#cypher_character_character_type").on("select2:select", function (e) {
    display_help(
      "/help/character_type",
      $("#cypher_character_character_type").val()
    );
  });

  $("#cypher_character_focus").on("select2:select", function (e) {
    display_help(
      "/help/focus",
      $("#cypher_character_focus").val());
    $("#cypher_character_custom_focus_div").toggle($("#cypher_character_focus").val() === "homebrew")
  });

  $("#cypher_character_flavor").on("select2:select", function (e) {
    display_help(
      "/help/flavor",
      $("#cypher_character_flavor").val());
    $("#cypher_character_custom_flavor_div").toggle($("#cypher_character_flavor").val() === "homebrew")
  });

  $("#cypher_character_genre").on("select2:select", function (e) {
    display_help(
      "/help/genre",
      $("#cypher_character_genre").val()
    );
  });

  $("#cypher_character_story_arc").on("select2:select", function (e) {
    display_help(
      "/help/story_arc",
      $("#cypher_character_story_arc").val()
    );
  });

  $("#cypher_character_gender").on("select2:select", function (e) {
    display_help(
      "/help/gender",
      $("#cypher_character_gender").val()
    );
  });

  $("#cypher_character_manual_genre").on("select2:select", function (e) {
    display_help(
      "/help/genre",
      $("#cypher_character_manual_genre").val()
    );
  });


  $("#cypher_character_creation_type").on("select2:select", function (e) {
    if ($("#cypher_character_creation_type").val() == 'manual') {
      $("#creation_type_manual").show()
      $("#creation_type_import").hide()
      $("#creation_type_genre").hide()
      $("#context_help_title").text("Help");
      $("#context_help").html("");
      $("#context_help_div").show()
    } else if ($("#cypher_character_creation_type").val() == 'import') {
      $("#creation_type_manual").hide()
      $("#creation_type_import").show()
      $("#creation_type_genre").hide()
      $("#context_help_div").hide()
    } else { // genre
      $("#creation_type_manual").hide()
      $("#creation_type_import").hide()
      $("#creation_type_genre").show()
      $("#context_help_div").show()
      display_help(
        "/help/" + "genre",
        $("#cypher_character_genre").val()
      );
    }

  });

  function titleize(string) {
    let firstCharacter = string.charAt(0);
    firstCharacter = firstCharacter.toUpperCase();
    let capitalizedString = firstCharacter + string.slice(1).toLowerCase();
    return capitalizedString.replace("_", " ").replace("-", " ");
  }

  function display_help(get_url, value) {
    $.ajax({
      type: "GET",
      url: get_url,
      data: { value: value },
      dataType: "json",
      success: function (data) {
        result = `<div>${data.help}</div>${data.attribution}`;
        title = titleize(value);
        if (result == "") {
          title = "Help";
        }

        $("#context_help_title").text(title);
        $("#context_help").html(result);
      },
    });
  }
});
